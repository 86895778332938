#port .card{
    border: 3px solid white;
    position: relative;
    border-radius: 10px;
    transition: all 0.5s ease-in;
    max-width: 18rem;
    min-width: 5rem;
    overflow: hidden;
    margin-top: 10px;
    max-height: 15rem;
}
#port .name{
    color: white;
    padding: 8px 8px 0px 10px;
    position: absolute;
    bottom: 0;
    border-radius:8px ;
}
#port .card .background{
    width: 18rem;
    /* min-width: 5rem; */
    background: linear-gradient(to top,#a771ffa1 , #4c00ff9c 20%);
    height: 9rem;
    position: absolute;
}
