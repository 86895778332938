#about, #team ,#services, #contact, #port, #home{
    background-image: url('../images/background.png');
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    color: white;
    overflow-x:hidden ;
    overflow-y: hidden;
}
@media (max-width: 650px) {
    #about, #team ,#services, #contact, #port, #home{
        overflow-x: scroll;
    }
    #about::-webkit-scrollbar, #team::-webkit-scrollbar ,#services::-webkit-scrollbar, #contact::-webkit-scrollbar, #port::-webkit-scrollbar, #home::-webkit-scrollbar{
        width: 3px;
        height: 3px;
    }
}
#about .content,#port .content, #team .content,#services .content, #contact .content, #home .content{
    background:radial-gradient(#6200ff7e,#4200ad70);
    min-height: 100vh;
    overflow:hidden ;
    position: relative;
}
#about .content h3, #team .content h3{
    text-align: center;
    text-decoration: underline;
    text-transform: capitalize;
    font-size: 1.3rem;
    color: white;
    margin-top: 140px;
}
#about .content h5{
    max-width: 56vw;
    color: white;
    letter-spacing: 0.03rem;
    margin-top: 50px;
    margin-left: 100px;
    font-size: 1.4rem;
}
#port .content .container h2, #contact .content h2{
    text-align: center;
    text-decoration: underline;
    color: white;
    text-transform: uppercase;
    letter-spacing: 0.05rem;
}
#port .content .container h5{
    margin: 15px 0;
    text-decoration: underline;
    color: white;
    text-transform: uppercase;
    letter-spacing: 0.03rem;
}
