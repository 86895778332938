

.stat_card {
    width: 250px;
    height: 160px !important;

    position: relative;
    border: none;
    border-radius: 12px;
    background: linear-gradient(to bottom, rgba(228, 192, 255, 0) 0%, rgba(228, 192, 255, 0.8) 100%), rgba(173, 87, 255, 0.8) !important;
    backdrop-filter: blur(500px);
    transition: all 0.3s ease;
    opacity: 1;

}


.card-body{
    text-align: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    font-family: 'share mono', monospace;
    font-size: 48px;
    color: #ffffff !important;
    font-weight: bold!important;

}




.card:before {
    content: '';
    position: absolute;
    top: -20px;
    left: -20px;
    right: -20px;
    bottom: -20px;
    border-radius: 10px;
    box-shadow: 0 0 50px rgba(255, 255, 255, 0.5),
    0 0 100px rgba(255, 255, 255, 0.3),
    0 0 200px rgba(255, 255, 255, 0.1);
    z-index: -1;
    opacity: 0;
    transition: all 0.3s ease;
}

.card:hover:before {
    opacity: 1;
}