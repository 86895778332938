@font-face {
    font-family: 'dune';
    src: url('/src/fonts/Dune_Rise.ttf');
}
#home .hero{
    margin-top: 150px;
}
#home .hero h1{
    font-size: 3rem;
    text-transform: uppercase;
    letter-spacing: 0.3rem;
    font-weight: 900;
    height: 4rem;
    font-family: 'dune';
    position: relative;
    overflow: hidden;
}
#home .hero h1::-webkit-scrollbar{
    width: 0px;
    height: 0px;
}

#home .hero-descrption{
    font-size:1.2rem;
    font-weight:600;
}

.stats{
    margin-top: 10rem!important;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}
@media (max-width:450px) {
    #home .hero h1{
        overflow-x: visible;
    }


}
@media (max-width:991px) {
    #home .hero h1{
        font-size: 2rem;
        height: 3rem;
    }

    .stat_card{
        width: 200px;
        height: 110px !important;
    }

    .card-body{
        text-align: center;
        align-items: center;
        display: flex;
        flex-direction: column;
        font-family: 'share mono', monospace;
        font-size: 36px;
        color: white!important;
        font-weight: bold!important;

    }

    
}

@media (max-width: 767px) {
    .stat_card{
        width: 150px;
        height: 70px !important;
    }


    .card-body{
        font-size: 24px;


    }

}


@media (max-width: 495px) {
    .stat_card {
        width: 150px;
        height: 100px !important;
        margin-bottom: 20px;
    }

    .card-body {
        display: flex; /* Add display flex */
        align-items: center; /* Center items vertically */
        justify-content: center; /* Center items horizontally */
        font-size: 24px;
        text-align: center !important;
    }

    .stats {
        margin-top: 10rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
    }
}

#home .hero h4{
    max-width: 55vw;
}
#home .btn-orange{
    background: #D9772C;
    font-size: 1.1rem;
    padding: 7px 10px;
    color: white;
    border-radius: 4px;
    transition: all ease-in 0.5s;
    border: white solid 0.7px;
    text-transform: uppercase;
    max-width: fit-content;
    letter-spacing: 0.05rem;
}
#home .btn-orange:hover, #home .btn-violet:hover{
    transform: scale(1.04);
}
#home .btn-violet{
    background: linear-gradient(to right,#BA99F0 40% ,#F3D9FF);
    font-size: 1.1rem;
    padding: 7px 10px;
    color: white;
    border-radius: 4px;
    transition: all ease-in 0.5s;
    border: white solid 0.7px;
    text-transform: uppercase;
    max-width: fit-content;
    letter-spacing: 0.05rem;
}
#home .stats{
    margin-top: 60px;
}
#home .stats .col-4{
    font-size: 1.3rem;
    text-transform: capitalize;
    font-weight: 600;
    text-align: center;
    border-radius: 80px;
    padding: 20px;
    border: none;
    z-index: 1;
    position: relative;
}
#home .content .container .stats .col-4 div{
    width: 130px;
    height: 130px;
    background: radial-gradient(#a771ff7a , #4c00ff6e);
    border-radius: 50%;
    position: absolute;
    left: -5px;
    top: -7px;
    filter: blur(15px);
    z-index: 0;
}