@font-face {
    font-family: 'share mono';
    src: url('https://fonts.googleapis.com/css2?family=Share+Tech+Mono&display=swap');
}
.App{
    font-family:'share mono', monospace ;
}
::-webkit-scrollbar{
    width: 8px;
    height: 8px;
}
::-webkit-scrollbar-track{
    background: #6200ff80;
}
::-webkit-scrollbar-thumb{
    background:linear-gradient(#642bff,#ff22e6);
}
