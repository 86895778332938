#team .card, #services .card{
    border: 3px solid white;
    background-color: #7730C1;
    color: white;
    max-width: 18rem;
    min-width: 5rem;
    border-radius: 25px;
    margin-top: 30px;
    transition: all 0.6s ease-in;
    height: 21rem;
    width: 16rem;

}
#team .img ,#services .img{
    /* width: 1rem; */
    /* height: 16.5rem; */
    object-fit: cover;
    overflow: hidden;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
}
.card:hover{
    transform: scale(1.03);
}