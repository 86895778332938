#header .link{
    font-size: 1.1rem;
    text-transform: capitalize;
    font-weight: 600;
    color: white;
    transition: all ease-in .4s;
    margin-right: 10px;
    text-decoration : none;
}
#header .link:hover{
    transform: scale(1.05);
}
#header .basic-btn{
    background: rgba(255, 255, 255, 0.486);
}
